import './src/styles/base.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPen,
  faHeadset,
  faDesktop,
  faEnvelope,
  faPhone,
  faCloud,
  faNetworkWired,
  faExpandArrowsAlt,
  faShareAlt,
  faTools,
  faThumbsUp,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(
  faPen,
  faHeadset,
  faTools,
  faCloud,
  faShareAlt,
  faNetworkWired,
  faBriefcase,
  faExpandArrowsAlt,
  faDesktop,
  faEnvelope,
  faPhone,
  faThumbsUp,
  faLinkedin,
)
